import React, { useCallback, useEffect, useRef } from 'react';
import LayoutContainer from 'components/LayoutContainer';
import tw, { styled } from 'twin.macro';
import { HFSModals, IMG_LINK_LIST } from 'components/constant';
import { graphql, useStaticQuery } from 'gatsby';
import ApplyButton from 'components/HFS/hfsHeader/components/ApplyButton';
import useHFS from 'components/HFS/hooks/useHFS';
import { useIntl } from 'react-intl';

const BackgroundVideo = tw.video`
  absolute z-[-1] w-full h-full object-cover
`;
const TextWrapper = tw.div`
  px-[30px] lg:px-0 z-[1] md:w-[900px] flex flex-col text-accent_grey_2 justify-center md:h-[calc(100vh_-_85px)] h-[calc(100vh_-_170px)]

`;
const Description = tw.p`
  text-[inherit] text-[16px] leading-[150%] lg:text-[24px] lg:font-[500] z-[1] mb-2 
`;
const OutlinedButton = tw.button`
border border-white px-3 py-[6px] rounded-[5px] hover:opacity-50
`;
const H1Wrapper = styled.p`
  h1 {
    ${tw`whitespace-pre-line text-accent_grey_2 z-[1] text-[36px] !font-[600] lg:text-[64px] leading-[120%] lg:leading-[100%] mb-9`}
  }
  u {
    text-decoration: underline;
    text-decoration-color: #00e0d8;
    text-underline-offset: 12px;
    text-decoration-thickness: 8px;
  }
`;

const HFSLandingHero = () => {
  const { setActiveModal } = useHFS();
  const { formatMessage } = useIntl();
  const data = useStaticQuery(graphql`
    query {
      allStrapiHfsLandingPage {
        nodes {
          heroMainBannerSubHeader
          heroMainBannerBackgroundImage {
            url
          }
          heroMainBannerBackgroundVideo {
            url
          }
          heroMainBannerHeader {
            data {
              heroMainBannerHeader
            }
          }
        }
      }
    }
  `);

  const {
    heroMainBannerSubHeader,
    heroMainBannerHeader: {
      data: { heroMainBannerHeader },
    },
    heroMainBannerBackgroundImage: { url: backgroundImg },
    heroMainBannerBackgroundVideo: { url: videoUrl },
  } = data.allStrapiHfsLandingPage.nodes[0];
  const videoRef = useRef<HTMLVideoElement>(null);
  const heightOfWindow =
    typeof window !== 'undefined' ? window.innerHeight : 50;
  const handleScroll = useCallback(async () => {
    const show = window.scrollY < heightOfWindow;
    try {
      if (videoRef.current) {
        if (show) {
          await videoRef.current.play();
        } else {
          videoRef.current.pause();
        }
      }
    } catch (error) {}
  }, [videoRef.current]);

  useEffect(() => {
    document.addEventListener('scroll', handleScroll);
    return () => {
      document.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <div className="relative">
      <BackgroundVideo
        autoPlay
        playsInline
        loop
        muted
        ref={videoRef}
        style={{
          background: `url(${
            backgroundImg ? backgroundImg : IMG_LINK_LIST.landingPage.hero
          }) no-repeat center center/cover`,
        }}
      >
        <source src={videoUrl} type="video/mp4" />
      </BackgroundVideo>

      <LayoutContainer>
        <TextWrapper>
          <Description>{heroMainBannerSubHeader}</Description>
          <H1Wrapper
            dangerouslySetInnerHTML={{ __html: heroMainBannerHeader }}
          />
          <div className="flex gap-2">
            <OutlinedButton
              onClick={() => {
                setActiveModal(HFSModals.CONTACT_US);
              }}
            >
              {formatMessage({ id: 'hfs.landing.btn.contactUs' })}
            </OutlinedButton>
            <ApplyButton />
          </div>
        </TextWrapper>
      </LayoutContainer>
    </div>
  );
};

export default HFSLandingHero;
